import React, { useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:path" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home() {
  const [originUrl, setOriginUrl] = React.useState();
  const { path } = useParams();

  useEffect(() => {
    async function fetch() {
        console.log("Show me the "+path);
        try {
            const { data } = await axios.get(`https://shorten-api.mldn.cc/url/${path}`);
            setOriginUrl(data.original_url);
        } catch (e) {
          console.log(e);
        }
    }
    fetch();
  }, [path]);

  useEffect(() => {
    console.log(originUrl);
    // window.location.href = originUrl;
    // window.location.replace(originUrl);
    if (originUrl) window.location.href = originUrl;
  }, [originUrl]);

  return (
      <></>
  )
}

export default App;
